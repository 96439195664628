import TagManager from 'react-gtm-module'
import { useEffect } from 'react';

const tagManagerArgs = {
    gtmId: process.env.NEXT_PUBLIC_GTM_KEY!,
    dataLayer: {
        userProject: process.env.NEXT_PUBLIC_GTM_PROJECT!
    }
}

const GoogleTagManager: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    useEffect(() => {
        TagManager.initialize(tagManagerArgs);
    }, [])

    return <>{children}</>
}

export default GoogleTagManager;
